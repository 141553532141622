<template>
  <div>
    <el-dialog :visible.sync="sta.show">
      <span slot="title">流水记录</span>
      <div class="row" v-loading="sta.loading" id="print">
        <el-card style="width:100%; margin-bottom: 20px">
          <el-divider content-position="center"><span style="color: #3bb667">基础信息</span></el-divider>
          <el-row>
            <el-col :span="10">
              <div class="form-group" style="color: #7e65ff">
                <label><b>物品：</b></label>
                {{ model.NAME }}
              </div>
            </el-col>
            <el-col :span="10">
              <div class="form-group">
                <label><b>仓库：</b></label>
                {{ model.STORAGE_NAME }}
              </div>
            </el-col>
            <el-col :span="4">
              <div class="form-group">
                <label><b>状态：</b></label>
                {{ model.STA_TXT }}
              </div>
            </el-col>
          </el-row>
        </el-card>
        <el-card style="width:100%; margin-bottom: 20px">
          <el-divider content-position="center"><span style="color: #3bb667">流水记录</span></el-divider>
          <el-table :data="records" stripe border :cell-style="{ padding: '0px' }"
            :header-cell-style="{ borderBottom: 'solid 1px #ccc', backgroundColor: '#f5f5f5', textAlign: 'center', padding: '5px 0px' }">
            <el-table-column prop="TYPE_TXT" label="业务类型" align="center" />
            <el-table-column prop="SN" label="单号" align="center">
              <template slot-scope="scope">
                <span @click="openMap(scope.row.SN)">{{ scope.row.SN }}</span>
              </template>
            </el-table-column>
            <el-table-column label="库存变动" align="center">
              <template slot-scope="scope">
                <span>{{ Math.trunc(scope.row.CNT) }}</span>
              </template>
            </el-table-column>
            <el-table-column prop="CREATED_DT" label="日期" width="120" align="center">
              <template slot-scope="scope">
                {{ scope.row.CREATED_DT | datetime('YYYY-MM-DD') }}
              </template>
            </el-table-column>
            <el-table-column label="备注" align="center">
              <template slot-scope="scope">
                {{ scope.row.DESCRIPTION }}
              </template>
            </el-table-column>
          </el-table>
        </el-card>
      </div>
      <div slot="footer">
        <!--        <el-button type="primary" icon="el-icon-printer" v-print="printObj">打印</el-button>-->
        <el-button icon="el-icon-close" @click="sta.show = false">关闭</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      sta: {
        show: false,
        loading: false
      },
      model: {},
      records: []
    }
  },
  methods: {
    init(r) {
      this.sta = { show: true, loading: true }
      this.model = r;
      let self = this;
      this.whale.remote.getResult({
        url: "/api/School/MAT/MBillApi/GetList",
        data: { ST_ID: r.ID },
        completed: function (res) {
          self.sta.loading = false
          self.records = res.DATA.ITEMS
        }
      })
    },
    openMap(r) {
      if (r) {
        this.$emit('openMat', r);
      }
    }
  }
}
</script>

<style scoped></style>