<template>
  <div>
    <el-dialog :visible.sync="sta.show">
      <span slot="title">物料损耗</span>
      <div class="row">
        <div class="col-md-12">
          <el-row>
            <el-col :span="8">
              <div class="form-group">
                <label><b>分类</b></label><br />
                <el-cascader disabled v-model="cat.val" :options="cat.ls" placeholder="请选择分类"
                  :props="{ label: 'NAME', value: 'ID', children: 'CHILDREN' }" />
              </div>
            </el-col>
            <el-col :span="8">
              <div class="form-group">
                <label><b>名称</b></label>
                <el-input disabled type="text" v-model="model.NAME" style="width: 90%;" />
              </div>
            </el-col>
            <el-col :span="8">
              <div class="form-group">
                <label><b>选择仓库</b></label><br />
                <el-select disabled v-model="model.STORAGE_ID" placeholder="请选择仓库" style="display: block">
                  <el-option :label="it.STORAGE_NAME" :value="it.ID" v-for="it in store" :key="it.ID" />
                </el-select>
              </div>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="8">
              <div class="form-group">
                <label><b>状态</b></label><br />
                <el-select disabled v-model="model.STA" placeholder="请选择状态">
                  <el-option label="正常" :value="1" />
                  <el-option label="禁用" :value="2" />
                </el-select>
              </div>
            </el-col>
            <el-col :span="8">
              <div class="form-group">
                <label><b>单位</b></label>
                <el-input disabled style="width: 90%;" type="text" v-model="model.FUNIT" />
              </div>
            </el-col>
          </el-row>
          <div class="form-group">
            <label><b>数量</b></label>
            <div>
              <el-input-number v-model="CNT" :step="1" :min="0"></el-input-number>
            </div>
          </div>
          <div class="form-group">
            <label><b>备注</b></label>
            <el-input type="text" v-model="model.DESCRIPTION" />
          </div>
        </div>
      </div>
      <div slot="footer" v-loading="sta.loading">
        <el-button icon="el-icon-close" @click="sta.show = false">取消</el-button>
        <el-button icon="el-icon-check" type="success" @click="submit">确认</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
export default {
  data() {
    return {
      sta: {
        show: false,
        loading: false
      },
      model: {},
      cat: {
        ls: [],
        val: []
      },
      store: [],
      fileList: [],
      CNT: 0,
      user: this.whale.user.get(),
    }
  },
  methods: {
    init(model) {
      this.fileList = []; //初始化，清空上传列表，无它用
      this.sta = { show: true, loading: false }
      this.cat = { ls: this.$parent.cat.ls, val: [] };
      this.store = this.$parent.store
      if (model == undefined || model == null) {
        this.model = {
          CVT: 1,
          STA: 1,
        };
      } else {
        this.model = JSON.parse((JSON.stringify(model)));
        let val = this.catFind(model.CAT_ID, this.cat.ls);
        if (val == undefined) val = model.CAT_ID + '';
        else val += "," + model.CAT_ID;
        this.cat.val = val.split(',').map(o => {
          return parseInt(o)
        })
        if (this.model.PIC_NAME) this.fileList = [{ name: this.model.PIC_NAME, url: this.model.PIC_URL }];
      }
    },
    catFind(id, ls) {
      let n = ls.length;
      for (var i = 0; i < n; i++) {
        let it = ls[i]
        if (it.LEAF) {
          if (it.ID != id) continue;
          return it.ANCESTORS;
        } else {
          var ret = this.catFind(id, it.CHILDREN);
          if (ret != undefined) return ret;
        }
      }
    },
    onChange(file) {
      let self = this;
      this.whale.image.compress({
        file: file.raw,
        callback(cf) {
          self.whale.image.toB64({
            file: cf,
            callback(b64) {
              self.model.PIC_B64 = b64;
            }
          })
        }
      })
    },

    submit() {
      let self = this;
      self.sta.loading = true;

      let datas = {
        ST_ID: self.model.ID,
        TYPE: 4,
        CNT: self.CNT,
        EVENT_DT: new Date(),
        HANDLE_NAME: self.user.NAME,
        DESCRIPTION: self.model.DESCRIPTION
      }
      this.whale.remote.getResult({
        url: "/api/School/MAT/MBillApi/Save",
        data: datas,
        finally() {
          self.sta.loading = false;
        },
        completed: function () {
          self.sta.show = false;
          self.$emit("on-saved");
        }
      })
    }
  }
}
</script>
